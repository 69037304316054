import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, merge, Observable, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
} from 'rxjs/operators';

import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';

import { Alerts } from 'src/app/common';

import { InventoryCounting } from 'src/app/models';
import { UserMapping2 } from 'src/app/models/db/user-mapping';
import {
  AlertService,
  BlockUIService,
  InvCountingsService,
  UsersService,
  InvAssignationsService,
} from 'src/app/services';

@Component({
  selector: 'app-assignation',
  templateUrl: './assignation.component.html',
  styleUrls: ['./assignation.component.scss'],
})
export class AssignationComponent implements OnInit, OnDestroy {
  @ViewChild('instance', { static: false }) instance: NgbTypeahead;
  focus$: Subject<string>;
  click$: Subject<string>;
  activeId: number;
  invCounting: InventoryCounting;
  users: UserMapping2[];
  assignedUsers: UserMapping2[];
  routerEventsSubs: Subscription;

  countingList: InventoryCounting[];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private invCountingService: InvCountingsService,
    private userService: UsersService,
    private alertSertvice: AlertService,
    private blockUI: BlockUIService,
    private assignationService: InvAssignationsService
  ) {
    this.activeId = Tabs.Countings;
    this.routerEventsSubs = this.router.events
      .pipe(filter((event$) => event$ instanceof NavigationEnd))
      .subscribe(() => {
        this.checkIfCountingId();
      });
    this.click$ = new Subject<string>();
    this.focus$ = new Subject<string>();
    this.invCounting = null;
    this.users = [];
    this.assignedUsers = [];
  }




  GetCountings() {
    this.invCountingService.GetInvCountings().subscribe(result => {

      if (result.Result) {
        this.countingList = result.Data.filter(i => i.Status < 2);

      }
    });
  }


  tst(id: InventoryCounting) {
    this.countingList.forEach(x => x.IsSelected = false);
    id.IsSelected = true;
    this.invCounting = id;
    this.getAssignedUsers(id.Id);
  }


  ngOnInit(): void {
    this.getUsers();

    this.GetCountings();
    this.checkIfCountingId();
  }

  ngOnDestroy() {
    if (this.routerEventsSubs) this.routerEventsSubs.unsubscribe();
  }

  onClickSearchUser = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (!term
          ? this.countingList
          : this.countingList.filter(
            (u) => u.Name.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        )
          ?.slice(0, 10)
          .map((u) => u.Name)
      )
    );
  };

  onSelectCounting($event: any) {
    $event.preventDefault();

    let countingSelected = this.countingList.find((x) => x.Name === $event.item);

    if (countingSelected) {
      this.invCounting = countingSelected;
      this.router.navigateByUrl('assignation/' + this.invCounting.Id);
      this.getUsers();
      this.getAssignedUsers(countingSelected.Id);

    }
  }

  DeleteAll(){

    console.log('delete all');
    this.users.forEach(element => {
      this.assignedUsers.push(element)
    });

    this.assignedUsers = [...this.assignedUsers];


    this.users = [];
    this.onClickSaveChanges()

  }
  AssingAll(){
    console.log('assign all')
    this.assignedUsers.forEach(element => {
      this.users.push(element)
    });

    this.users = [...this.users];

    this.assignedUsers = []

    this.onClickSaveChanges()
  }
  onClickDeleteAssignation(index: number) {
    this.assignedUsers.splice(index, 1);
  }

  onClickSaveChanges() {
    if (!this.invCounting) return;

    this.blockUI.start('Procesando...');

    const usersId = this.assignedUsers.map((x) => x.Id);

    this.assignationService
      .PostAssignation(usersId, this.invCounting.Id)
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();
          this.alertSertvice.HandleApiResponse(response);

        },
        (err) => {
          this.blockUI.stop();
          this.alertSertvice.HandleUnknownError(err);
        }
      );
  }

  getUsers() {
    this.userService
      .GetUserMappings()
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result) this.users = response.Data;
        },
        (err) => {
          this.alertSertvice.HandleApiResponse(err);
        }
      );
  }

  preloadInvCounting(invCountingId: number) {
    forkJoin({
      invCouting: this.invCountingService.GetInvCounting(invCountingId),
    }).subscribe((response) => {
      if (response.invCouting.Result) {
        this.invCounting = response.invCouting.Data;
        this.getAssignedUsers(this.invCounting.Id)
      }
    });
  }

  getAssignedUsers(invCountingId: number) {
    if (invCountingId) {
      this.invCountingService.getAssignedUsers(invCountingId).subscribe(data => {
        if (data.Result) {

          this.assignedUsers = data.Data;
          this.users = this.users.filter(m => this.assignedUsers.findIndex(i => i.Id === m.Id) === -1)

        }
      });



    } else {
      console.log('invCounting null');

    }

  }

  checkIfCountingId() {
    let invCountingId =
      +this.activatedRoute.snapshot.paramMap.get('invCountingId');
    if (invCountingId > 0) {
      this.activeId = Tabs.Assignation;
      this.preloadInvCounting(invCountingId);
    }
  }

  //----------------------------------------
  AddUser(user: UserMapping2) {

    this.assignedUsers.push(user);
      //se elimina de la lista de los usuarios disponibles
    const index = this.users.findIndex(e => e.User.Email === e.User.Email)
    if (index > -1) {
      this.users.splice(index, 1);
    }

    this.onClickSaveChanges();



  }

  drop(event: CdkDragDrop<UserMapping2[]>) {

    console.log(event ,  event.previousContainer === event.container)
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,

      );


    }
    this.onClickSaveChanges();
 }


  DeleteUser(user){
    this.users.push(user)
    const index = this.users.findIndex(e => e.User.Email === e.User.Email)
    this.assignedUsers.splice(index, 1);

    this.onClickSaveChanges()

  }




}

export enum Tabs {
  Countings = 1,
  Assignation = 2,
}
